<template>
  <div class="dept-add-box">
    <van-nav-bar v-if="this.title" :title="title" />
    <van-nav-bar
      v-else
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <div class="list-box">
        <div class="filed-box">
          <van-field
            v-model="deptForm.deptName"
            name="部门"
            label="部门"
            required
            placeholder="请输入部门"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
      </div>
      <div class="btn-box">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
  export default {
    name: 'editInfo',
    data() {
      return {
        title: '新增部门',
        deptForm: {
          deptId: null,
          companyId: null,
          deptName: null,
        },
      };
    },
    mounted() {
      let { query } = this.$route;
      if (query.companyId) {
        this.deptForm.companyId = query.companyId;
      }
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      onSubmit() {
        this.$http
          .post('/selfhelpOfficialAccounts/companyDept', this.deptForm)
          .then((res) => {
            if (res.code === 200) {
              this.$toast('新增部门成功，正在为你跳转中...');
              this.onClickLeft();
            } else {
              this.$toast(res.message || '新增部门失败');
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dept-add-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .van-form {
      flex: 1;
      overflow-y: auto;
    }

    .list-box {
      padding: 0 16px;
      margin: 20px 0 40px 0;
      > p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .filed-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebedf0;
        > .van-cell {
          flex: 1;
        }
        > .van-cell::after {
          border-bottom: none;
        }
        img {
          width: 77px;
          height: 23px;
        }
        ::v-deep .van-field--disabled .van-field__label {
          color: #646566;
        }
        ::v-deep .van-field__value input {
          text-align: right;
        }
      }
      .van-search .van-cell {
        padding: 5px 8px 5px 0;
        ::v-deep .van-field__value input {
          text-align: initial;
        }
      }
      .van-uploader {
        width: 100%;
      }
      ::v-deep .van-uploader__input-wrapper {
        width: 100%;
      }
      .uploader-btn {
        height: 45px;
        line-height: 45px;
        background: #fafafa;
        border-radius: 4px;
        border: 1px dashed #cccccc;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        i {
          color: #f44336;
          font-style: normal;
        }
      }
    }

    .btn-box {
      margin-top: 40px;
      padding: 0 96px;
    }
  }
</style>
